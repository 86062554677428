import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SegurancaModule } from './seguranca/seguranca.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        // BrowserModule.withServerTransition({appId: 'serverApp'}),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ToastModule,
        MessageModule,
        MessagesModule,

        CoreModule,

        SegurancaModule,
        AppRoutingModule
    ],
    providers: [MessageService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
