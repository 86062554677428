import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cs-page-card',
  templateUrl: './cs-page-card.component.html',
  styleUrls: ['./cs-page-card.component.scss']
})
export class CsPageCardComponent implements OnInit {
  @Input()
  header: string;

  constructor() { }

  ngOnInit(): void {
  }

}
