import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

import { NotAuthenticatedError } from '../seguranca/money-http-interceptor';

@Injectable()
export class ErrorHandlerService {

    constructor(
        private messageService: MessageService,
        private router: Router
    ) {
    }

    handle(errorResponse: any) {
        let msg: string;
        let close: boolean = true;
        let sticky: boolean = false;

        if (typeof errorResponse === 'string') {
            msg = errorResponse;
        } else if (errorResponse instanceof NotAuthenticatedError) {
            msg = 'Sua sessão expirou!';
            this.router.navigate(['/login']);

        } else if (errorResponse instanceof HttpErrorResponse
            && errorResponse.status >= 400 && errorResponse.status <= 499) {
            msg = 'Ocorreu um erro ao processar a sua solicitação';
            if (errorResponse.status === 403) {
                msg = 'Você não tem permissão para executar esta ação';
            }
            if (errorResponse.status === 400) {
                msg = errorResponse.error.detail;
            }
            try {
                msg = errorResponse.error.userMessage;
            } catch (e) {
            }

            console.error('Ocorreu um erro', errorResponse);

        } else {
            msg = 'Erro ao processar serviço remoto. Tente novamente.';
            console.error('Ocorreu um erro', errorResponse);
        }

        this.messageService.add({severity:'error', summary: 'Atenção', detail: msg, closable: close, sticky: sticky});
    }

}
