import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DashboardComponent } from '../../../dashboard/dashboard.component';
import { AuthService } from '../../../seguranca/auth.service';
import { LogoutService } from '../../../seguranca/logout.service';
import { ErrorHandlerService } from '../../error-handler.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styleUrls: ['./app.menu.component.scss']
})
export class AppMenuComponent implements OnInit {

    model: any[];

    constructor(
        public app: DashboardComponent,
        private authService: AuthService,
        private router: Router,
        private logoutService: LogoutService,
        private errorHandler: ErrorHandlerService
    ) {
    }

    ngOnInit() {
        this.model = [
            {label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/']},
            {
                label: 'Usuário',
                icon: 'pi pi-fw pi-user',
                // visible: this.authService.temPermissao('GERENCIAR_USUARIOS'),
                items: [
                    {
                        label: 'Cadastro',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['usuario/cadastro'],
                        // visible: this.authService.temPermissao('GERENCIAR_USUARIOS'),
                    },
                    // {
                        // label: 'Solicitações de Acesso',
                        // icon: 'pi pi-fw pi-unlock',
                        // routerLink: ['/app/usuario/acesso/pesquisa'],
                        // visible: this.authService.hasAnyModuleGrant(["GERENCIAR_CLIENTE"]),
                    // },

                ]},
            {label: 'Sair', icon: 'pi pi-fw pi-sign-out', command: (event) => this.logout()}
        ];
    }

    onMenuClick() {
        this.app.menuClick = false;
    }


    logout() {
        this.logoutService.logout()
            .then(() => {
                this.router.navigate(['/public/login']);
            })
            .catch(erro => this.errorHandler.handle(erro));
    }
}
