import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {SegurancaComponent} from "./seguranca.component";
import { LoginComponent } from './login/login.component';


const routes: Routes = [
    {
        path: 'public', component: SegurancaComponent, children: [
            {path: 'login', component: LoginComponent},
            {path: 'recover', component: LoginComponent},
            {path: 'signup', component: LoginComponent}
        ]
    },

];


@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class SegurancaRoutingModule {
}
