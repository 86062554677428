import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AppAccessdeniedComponent } from './core/pages/app.accessdenied.component';
import { AppNotfoundComponent } from './core/pages/app.notfound.component';
import { AuthGuard } from './seguranca/auth.guard';

const routes: Routes = [
    { path: '', loadChildren: () => import('../app/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard]},
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },

    { path: 'nao-autorizado', component: AppAccessdeniedComponent },
    { path: 'pagina-nao-encontrada', component: AppNotfoundComponent },
    { path: '**', redirectTo: 'pagina-nao-encontrada' }
  ];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
