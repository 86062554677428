import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cs-form-title-group',
  templateUrl: './form-title-group.component.html',
  styleUrls: ['./form-title-group.component.scss']
})
export class FormTitleGroupComponent implements OnInit {
  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
