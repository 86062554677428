import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { AuthService } from './auth.service';

export class NotAuthenticatedError {}

@Injectable()
export class MoneyHttpInterceptor implements HttpInterceptor {

    private allowedDomains = [
        "https://viacep.com.br",
        "/v1/conta/nova",
        "/v1/unidade-processamento/por-subdominio", 
        "/v1/usuario/solicitacao-acesso",
        "/v1/conta/por-subdominio"
    ];

    constructor(private auth: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if ((!req.url.includes('/oauth/token') && !this.isAllowedDomain(req.url)) && this.auth.isAccessTokenInvalido()) {
            return from(this.auth.obterNovoAccessToken())
                .pipe(
                    mergeMap(() => {
                        if (this.auth.isAccessTokenInvalido()) {
                            throw new NotAuthenticatedError();
                        }

                        req = req.clone({
                            setHeaders: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`
                            }
                        });

                        return next.handle(req);
                    })
                );
        }

        return next.handle(req);
    }

    isAllowedDomain(url: string): boolean {
        return this.allowedDomains.some(domain => url.includes(domain));
    }
}
