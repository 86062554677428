import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { APP_CONFIG } from './config';

fetch('./assets/app.config.json')
	.then((resp) => resp.json())
	.then((config) => {
		platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
		.bootstrapModule(AppModule)
		.catch((err) => console.error(err));
	});

if (environment.production) {
  enableProdMode();
}

