import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { AuthService } from '../auth.service';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loading = false;
    subdominio: string;
    returnUrl: string;
    error = '';
    currentUser: any;

    constructor(
        private auth: AuthService,
        private errorHandler: ErrorHandlerService,
        private router: Router
    ) {
    }


    ngOnInit(): void {
        this.subdominio = document.location.hostname.replace(environment.dominio, '');
        if (this.subdominio != null && this.subdominio != '') {
            this.subdominio = this.subdominio.replace('.', '')
        }
    }

    onSubmit(loginForm: NgForm) {
        this.loading = true;
        this.auth.login(loginForm.form.value.username, loginForm.form.value.password)
            .then(() => {
                this.currentUser = this.auth.jwtPayload;
                if (this.currentUser.update_senha) {
                    this.router.navigate(['/update-senha']);
                } else {
                    this.router.navigate(['/']);
                }
                this.loading = false;
            })
            .catch(erro => {
                this.errorHandler.handle(erro);
            });
    }

}
