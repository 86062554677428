import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';

import { AuthService } from '../../../seguranca/auth.service';
import { LogoutService } from '../../../seguranca/logout.service';
import { ErrorHandlerService } from '../../error-handler.service';
import { BreadcrumbService } from './breadcrumb.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './app.breadcrumb.component.html'
})
export class AppBreadcrumbComponent implements OnDestroy {

    currentUser: any;
    subscription: Subscription;

    items: MenuItem[];

    constructor(
        public breadcrumbService: BreadcrumbService,
        private logoutService: LogoutService,
        public auth: AuthService,
        private errorHandler: ErrorHandlerService,
        private router: Router
        ) {
        // this.authService.currentUser.subscribe(x => this.currentUser = x);
        this.currentUser = auth.jwtPayload;
        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    logout() {
        this.logoutService.logout()
            .then(() => {
                window.location.reload();
            })
            .catch(erro => this.errorHandler.handle(erro));
    }
}
