import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardModule } from 'primeng/card';

import { CsPageCardComponent } from './cs-page-card/cs-page-card.component';
import { FormFieldComponent } from './form-field/form-field.component';
import { FormTitleGroupComponent } from './form-title-group/form-title-group.component';
import { LoadingSmallComponent } from './loading -small/loading-small.component';
import { LoadingComponent } from './loading/loading.component';
import { MessageComponent } from './message/message.component';
import { TimeAgoPipe } from './time-ago/time-ago.pipe';

@NgModule({
    declarations: [
        CsPageCardComponent,
        FormFieldComponent,
        FormTitleGroupComponent,
        LoadingComponent,
        LoadingSmallComponent,
        MessageComponent,
        TimeAgoPipe
    ],
    imports: [
        CommonModule,
        CardModule
    ],
    exports: [
        CsPageCardComponent,
        FormFieldComponent,
        FormTitleGroupComponent,
        LoadingComponent,
        LoadingSmallComponent,
        MessageComponent,
        TimeAgoPipe
    ]
})
export class CustomComponetsModule {
}
