import { Component, Input } from '@angular/core';

@Component({
  selector: 'cs-loading',
  template: `
    <ng-container *ngIf="show">
      <div class="loading-container">
        <div class="loading-spinner">
            <span class="loading-dot-1"
              [style]="'width:' + dotWidth + ';height:' + dotWidth">
            </span>
            <span class="loading-dot-2"
              [style]="'width:' + dotWidth + ';height:' + dotWidth">
            </span>
            <span class="loading-dot-3"
              [style]="'width:' + dotWidth + ';height:' + dotWidth">
            </span>
            <span class="loading-dot-4"
              [style]="'width:' + dotWidth + ';height:' + dotWidth">
            </span>
            <span class="loading-dot-5"
              [style]="'width:' + dotWidth + ';height:' + dotWidth">
            </span>
            <span class="loading-dot-6"
              [style]="'width:' + dotWidth + ';height:' + dotWidth">
            </span>
            <span class="loading-dot-7"
              [style]="'width:' + dotWidth + ';height:' + dotWidth">
            </span>
            <span class="loading-dot-8"
              [style]="'width:' + dotWidth + ';height:' + dotWidth">
            </span>
          </div>
      </div>
    </ng-container>
  `,
  styleUrls: [ './loading.component.scss' ]
})
export class LoadingComponent {

  @Input()
  show: boolean = true;

  @Input()
  dotWidth: string = '12px';

}
