import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { NgxViacepModule } from '@brunoc/ngx-viacep';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { PasswordModule } from 'primeng/password';
import { StepsModule } from 'primeng/steps';

import { environment } from '../../environments/environment';
import { CustomComponetsModule } from '../shared/custom-componets/custom-componets.module';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './login/login.component';
import { LogoutService } from './logout.service';
import { MoneyHttpInterceptor } from './money-http-interceptor';
import { SegurancaRoutingModule } from './seguranca-routing.module';
import { SegurancaComponent } from './seguranca.component';

export function tokenGetter(): string {
    return localStorage.getItem('token');
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        InputTextModule,
        ButtonModule,
        CalendarModule,
        DropdownModule,
        InputMaskModule,
        InputNumberModule,
        InputSwitchModule,
        PasswordModule,
        MessagesModule,
        NgxViacepModule,
        StepsModule,

        CustomComponetsModule,

        JwtModule.forRoot({
            config: {
                tokenGetter,
                allowedDomains: environment.tokenAllowedDomains,
                disallowedRoutes: environment.tokenDisallowedRoutes
            }
        }),

        SegurancaRoutingModule,
        CardModule,
    ],
    declarations: [
        LoginComponent,
        SegurancaComponent
    ],
    providers: [
        JwtHelperService,

        {
            provide: HTTP_INTERCEPTORS,
            useClass: MoneyHttpInterceptor,
            multi: true
        },
        AuthGuard,
        LogoutService
    ]
})
export class SegurancaModule {
}
