import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Config } from 'protractor';
import { APP_CONFIG } from 'src/config';

@Injectable()
export class LogoutService {

    tokensRenokeUrl: string;

  constructor(
    @Inject(APP_CONFIG) readonly config: Config,
    private http: HttpClient,
    private router: Router,
    private auth: AuthService,
  ) {
    this.tokensRenokeUrl = `${this.config.apiUrl}/tokens/revoke`;
  }

  logout() {
    return this.http.delete(this.tokensRenokeUrl, { withCredentials: true })
      .toPromise()
      .then(() => {
        this.auth.limparAccessToken();
      });
  }

}
