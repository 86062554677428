import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'timeAgo'})
export class TimeAgoPipe implements PipeTransform {

  private _ss = 1000;
  private _mm = this._ss * 60;
  private _hh = this._mm * 60;
  private _dd = this._hh * 24;
  private _MM = this._dd * 30;
  private _yy = this._dd * 365;

  transform(value: any): string {

    const dataAtual = new Date();
    const data: Date = new Date(value);

    // se nao for data valida
    if(isNaN(data.getTime())) {
      return '';
    }

    const dataDiff = Math.abs(dataAtual.getTime() - data.getTime());

    const anos = Math.floor(dataDiff / this._yy);
    if(anos > 0) return `${ anos } ${ anos > 1 ? 'anos' : 'ano' }`;

    const meses = Math.floor(dataDiff / this._MM);
    if(meses > 0) return `${ meses } ${ meses > 1 ? 'meses' : 'mês' }`;

    const dias = Math.floor(dataDiff / this._dd);
    if(dias > 0) return `${ dias } ${ dias > 1 ? 'dias' : 'dia' }`;

    const horas = Math.floor(dataDiff / this._hh);
    if(horas > 0) return `${ horas } ${ horas > 1 ? 'horas' : 'hora' }`;

    const minutos = Math.floor(dataDiff / this._mm);
    if(minutos > 0) return `${ minutos } min`;

    return 'agora';
  }

}
