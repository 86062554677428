import { Component, Input, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'cs-form-field',
  template: `
      <div class="form-field">
      <p class="label" for="numeroFuncionarios" 
          [class.error]="!field?.valid && field?.dirty">
          {{label}}{{field?.errors?.required ? '*' : ''}}
      </p>

      <ng-content></ng-content>
      
      <p *ngIf="!field?.valid && field?.dirty" class="message error" style="display: block;">
        <span>{{field?.errors?.required ? 'O campo ' +field?.name+ ' é obrigatório.' : ''}}</span>
        <span>{{field?.errors?.email ? 'Email invalido.' : ''}}</span>
      </p>
    </div>
  `,
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements OnInit {
  @Input() label: string;
  @Input() field: NgModel;

  constructor() { }

  ngOnInit(): void {
  }
}
