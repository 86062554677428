import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { DashboardComponent } from '../../../dashboard/dashboard.component';
import { AuthService } from '../../../seguranca/auth.service';
import { LogoutService } from '../../../seguranca/logout.service';
import { ErrorHandlerService } from '../../error-handler.service';

@Component({
    selector: 'app-rightpanel',
    templateUrl: './app.rightpanel.component.html',
    styleUrls: ['./app.rightpanel.component.scss']
})

export class AppRightPanelComponent {
    today: string;
    currentUser: any;

    constructor(
        public app: DashboardComponent,
        public auth: AuthService,
        private logoutService: LogoutService,
        private errorHandler: ErrorHandlerService,
        private router: Router
    ) {
        this.today = this.getDate();
        this.currentUser = auth.jwtPayload;
    }

    getDate(): string {
        let dt = new Date();

        let month = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
        let week = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];

        let semana = week[dt.getDay()];
        let mes = month[dt.getMonth()];

        return semana + ', ' + dt.getDate() + ' de ' + mes + ', ' + dt.getFullYear();
    }

    logout() {
        this.logoutService.logout()
            .then(() => {
                this.router.navigate(['/public/login']);
            })
            .catch(erro => this.errorHandler.handle(erro));
    }
}

