import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { DashboardComponent } from '../../../dashboard/dashboard.component';
import { AuthService } from '../../../seguranca/auth.service';
import { LogoutService } from '../../../seguranca/logout.service';
import { ErrorHandlerService } from '../../error-handler.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    activeItem: number;
    currentUser: any;

    constructor(
        public app: DashboardComponent,
        public auth: AuthService,
        private logoutService: LogoutService,
        private errorHandler: ErrorHandlerService,
        private router: Router
    ) {
        this.currentUser = auth.jwtPayload;
    }

    mobileMegaMenuItemClick(index) {
        this.app.megaMenuMobileClick = true;
        this.activeItem = this.activeItem === index ? null : index;
    }

    logout() {
        this.logoutService.logout()
            .then(() => {
                window.location.reload();
            })
            .catch(erro => this.errorHandler.handle(erro));
    }

}
