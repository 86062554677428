import { CommonModule, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { AuthService } from '../seguranca/auth.service';
import { ErrorHandlerService } from './error-handler.service';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppBreadcrumbComponent } from './template/breadcrumb/app.breadcrumb.component';
import { BreadcrumbService } from './template/breadcrumb/breadcrumb.service';
import { AppMenuComponent } from './template/menu/app.menu.component';
import { MenuService } from './template/menu/app.menu.service';
import { AppMenuitemComponent } from './template/menu/app.menuitem.component';
import { AppRightPanelComponent } from './template/rightpanel/app.rightpanel.component';
import { AppTopBarComponent } from './template/topbar/app.topbar.component';

registerLocaleData(localePt);

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ConfirmDialogModule,
    ],
    declarations: [
        AppBreadcrumbComponent,
        AppRightPanelComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopBarComponent,
        AppAccessdeniedComponent,
        AppErrorComponent,
        AppNotfoundComponent


    ],
    exports: [
        AppBreadcrumbComponent,
        AppRightPanelComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopBarComponent,
        AppAccessdeniedComponent,
        AppErrorComponent,
        AppNotfoundComponent,

        ConfirmDialogModule
    ],
    providers: [
        ErrorHandlerService,

        ConfirmationService,
        Title,
        AuthService,

        BreadcrumbService,
        MenuService,
        {provide: LOCALE_ID, useValue: 'pt-BR'}
    ]
})
export class CoreModule {
}
